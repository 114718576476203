import React, { useState } from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
import "../../css/typography-cerabielefeld.css"
const ChatComponent = loadable(() =>
  import("../../components/prod/custom-page")
)

const useStyles = makeStyles({
  circle: {
    position: "absolute",
    height: "385px",
    width: "489px",
    bottom: 0,
    right: "2%",
  },
  dots: {
    position: "absolute",
    left: "10%",
    top: "100px",
  },
})

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  const classes = useStyles()
  // TODO: set
  const origDynamicStyleOptions = {
    hideSendBox: true,
  }
  const [hideSendBox, setHideSendBox] = useState(
    origDynamicStyleOptions.hideSendBox
  )

  function onHandleRestart() {
    setHideSendBox(origDynamicStyleOptions.hideSendBox)
  }

  return (
    <ChatComponent
      name="Dienstleistungsfinder"
      pageTitle="Convaise - Dienstleistungsfinder"
      // homepage="#"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
      botId="ddxng5wgkx4esxti"
      avatar={false}
      avatarInitials={false}
      logo='data:image/svg+xml;utf8,<svg viewBox="0 0 60 84" xmlns="http://www.w3.org/2000/svg"><path d="m60 84h-13v-51.59h13zm-20-15.32c0 9.07-7.21 15.32-16.78 15.32h-23.22v-51.59h18c9.49 0 16.8 6.1 16.8 15.24a13.1 13.1 0 0 1 -3 8.68 12.69 12.69 0 0 1 8.2 12.35m-27.56-15.87h5c3.09 0 5-1.79 5-4.53s-1.88-4.53-5-4.53h-5zm14.56 15.32c0-3-2-4.53-5-4.53h-9.56v9.07h9.61c3 0 5-1.49 5-4.54m32.95-68.13h-13v11.15h-10.83v-11.15h-12.34v11.15h-10.83v-11.15h-13v24h60z" fill="%23e30014"></path></svg>'
      domain="https://europe.directline.botframework.com/v3/directline"
      origDynamicStyleOptions
      onHandleRestart={onHandleRestart}
      styleOptions={{
        primaryFont: '"CeraBielefeld",Arial,sans-serif',
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "rgb(227,0,20)",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        // primaryFont:
        //   '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      styleCustomizations={{
        "--button-blue": "rgb(227,0,20)",
        "--button-blue-light": "rgb(196,13,14)",
        "--button-orange": "rgb(77,112,129)",
        "--button-orange-light": "rgb(67,98,114)",
        "--button-orange-text": "#fff",
        "--button-red": "rgb(227,0,20)",
        "--button-red-light": "rgb(196,13,14)",
        "--button-green": "rgba(0, 131, 50)",
        "--button-green-light": "rgba(0,124,47)",
        "--font": '"CeraBielefeld",Arial,sans-serif',
        "--button-font-size": "16px",
        "--button-font-weight": "400",
        "--button-border-radius": "0",
        "--button-box-shadow": "rgb(0 0 0 / 25%) 0px 0px 20px 0px",
        "--button-padding": "8px 24px !important",
        "--button-height": "42px",
        "--card-border-radius": "20px",
        "--font-size-normal": "16px",
        "--line-height-normal": "1.5em",
        "--font-size-large": "36px",
      }}
      onHideSendBox={value => {
        // console.log("changing hideSendbox")
        setHideSendBox(value)
      }}
      backgroundColor="#F0F4F5"
      customs={[
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          className={classes.circle}
          role="presentation"
          alt=""
          key="customization-circle"
        />,
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          className={classes.dots}
          key="customization-dots"
          role="presentation"
          alt=""
        />,
      ]}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
